import React from 'react'
import SwipeableCards from '../../sub-components/SwipeableCards'


const TopCategories = ({ history, categories }) => {
    const ids = categories.map(category => category.id).join()
    return (
        <SwipeableCards
            primaryCaptionFont={false}
            title={'Top categories'}
            link={{
                to: `/seeall?title=Top Categories&numOfCol=3&ids=${ids}&type=category`,
                text: 'See all'
            }}
            slidesPerView={4.2}
            pagination={false}
            actions={categories.map(category => {
                const url = `/seeall?title=${category.name}&numOfCol=4&ids=${category.caratIds}&type=carat`
                return { action: () => history.push(url) }
            })}
            images={categories.map(category => {
                return {
                    src: category.imageUrl,
                    alt: category.name
                }
            })}
            captions={categories.map(category => category.name)}
        />
    );
};

export default TopCategories;