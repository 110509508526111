import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/signup.css'

const Signup = () => {

    const signUpObjects = {
        header: 'COSMIC CARAT',
        subHeader: 'Welcome',
        signup: 'Please Sign Up!',
        imageSrc: '/icons/app-icon.svg',
        forgotPass: 'Forgot Password?',
        signUp: 'Sign Up'
    }

    return (
        <>
            <div className="">
                <div className="screen-container text-center">
                    <div className="skip-link-container">
                        <Link to="/shop"><p>Skip &gt;&gt;</p></Link>
                    </div>

                    <img className="img__styling" src="/icons/app-icon.svg" alt="Cosmic Carat Logo" />

                    <p className="header-style">{signUpObjects.header}</p>
                    <p className="welcome-sub-header-style">{signUpObjects.subHeader}</p>
                    <p className="action-sub-sub-header">{signUpObjects.signup}</p>


                    <input type="text" placeholder="Name" className="input-border" />

                    <input type="email" placeholder="Email" className="input-border" />

                    <input type="password" placeholder="Password" className="input-border" />

                    <input type="password" placeholder="Confirm Password" className="input-border" />

                    <div className="submit__btn__container  mt-4">
                        <div className="primary-button-style sign-in-button-format"><p>{signUpObjects.signUp}</p></div>
                    </div>

                    <div className="link-to-sign-up">
                        <p>Already have an account? <a href="/login" style={{ fontWeight: "bold" }}> Click here</a></p>
                    </div>



                </div>
            </div>
        </>
    );
}

export default Signup
