import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import ActionStrip from '../../sub-components/ActionStrip'
import Card from '../../sub-components/Card'

import './cart.css'

const Cart = ({
    history,
    carats,
    setCarats,
    cart,
    setCart,
    setShowModal,
    setModalOptions,
    decrementCart,
    incrementCart
}) => {

    const [checkedCarats, setCheckedCarats] = useState(new Set())

    const handleChange = (checked, caratId) => {
        let deepCheckedCarats = new Set() //use this for selectAll

        Array.from(checkedCarats.values()).forEach((value) =>
            deepCheckedCarats.add(value)
        )

        if (checked) {
            deepCheckedCarats.add(caratId)
        } else if (checked === false) {
            deepCheckedCarats.delete(caratId)
        }
        setCheckedCarats(deepCheckedCarats)
    }

    const selectAllBtnClick = (checked) => {
        if (checked) {
            let selectedCarats = new Set()
            cart.forEach((qty, caratId) => {
                selectedCarats.add(caratId)
            })
            setCheckedCarats(selectedCarats)
        } else {
            setCheckedCarats(new Set())
        }
    }

    const onEmptied = useCallback(() => {
        setModalOptions({
            icon: './icons/app-icon.svg',
            message: 'Your cart is empty!',
            onClickPrompt: 'Okay',
            onClick: () => {
                setShowModal(false)
                history.goBack()
            },
            onClose: () => { }
        })
        setShowModal(true)
    }, [history, setModalOptions, setShowModal])

    const deleteChecked = () => {
        cart.forEach((qty, caratId) => {
            if (checkedCarats.has(caratId)) {
                cart.delete(caratId)
                setCart(new Map(cart))
            }
        })
    }

    const deleteFromCart = (caratId) => {
        cart.delete(caratId)
        setCart(new Map(cart))
    }

    const cartOptions = [
        { label: `${checkedCarats.size} items selected` },
        { checked: true, onTouch: (event) => selectAllBtnClick(event.target.checked) },
        { label: 'Select All' },
        { iconSrc: './icons/trash-icon.svg', onTouch: deleteChecked },
        { label: 'Delete Selected' }
    ]

    useEffect(() => {
        if (cart.size === 0 && new Map(JSON.parse(localStorage.cart)).size === 0) {
            onEmptied()
        }
    }, [cart, onEmptied])

    return (
        <>
            <ActionStrip touchables={cartOptions} />

            {Array.from(cart.entries()).map((entry) => {
                const caratId = entry[0]
                const carat = carats.get(caratId)
                const qty = entry[1]
                return (
                    <Card
                        key={carat.id}
                        imageSrc={carat.imageUrl}
                        imageAlt={carat.name}
                        detail={{
                            heading: carat.name,
                            subHeading: 'Special Design',
                            subSubHeading: `$ ${carat.price}`,
                            touchables: [
                                {
                                    iconSrc: './icons/minus-icon.svg',
                                    onTouch: () => decrementCart(carat, 1)
                                },
                                { label: qty },
                                {
                                    iconSrc: './icons/plus-icon.svg',
                                    onTouch: () => incrementCart(carat, 1)
                                },
                                {
                                    iconSrc: './icons/trash-icon.svg',
                                    onTouch: () => deleteFromCart(carat.id)
                                }
                            ]
                        }}
                        selectable={{
                            onSelected: handleChange,
                            checked: checkedCarats.has(carat.id),
                            caratId: carat.id
                        }}
                    />
                )
            })}

            <div className="button__container">
                <Link to="/checkout">
                    <button className="primary-button-style">Proceed To Checkout</button>
                </Link>
            </div>
        </>
    )
}

export default Cart

