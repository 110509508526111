import React, { useState, useCallback } from 'react'
import { Modal } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import './ImageEditor.css'

const ImageEditor = ({
    showModal,
    imageSrc,
    onCropCancel,
    onCropConfirm
}) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback(async (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const resetState = useCallback(() => {
        setCrop({ x: 0, y: 0 })
        setRotation(0)
        setZoom(1)
        setCroppedAreaPixels(null)
    }, [])

    return (
        <Modal show={showModal} onHide={() => {}} centered>
            <Modal.Header><Modal.Title>Adjust image</Modal.Title></Modal.Header>
            <Modal.Body>
                <div className={"cropContainer"}>
                    <Cropper
                        image={imageSrc}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='secondary-button-style' onClick={onCropCancel}>Cancel</button>
                <button
                    className='primary-button-style'
                    onClick={() => {
                        onCropConfirm(croppedAreaPixels, rotation)
                        resetState()
                    }}
                >
                    Confirm
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImageEditor