import React from 'react';
import SwipeableCards from '../../sub-components/SwipeableCards';

const TodaysTrending = ({ date, carats, onCaratSelected, quickAddToCart }) => {
    return (
        <SwipeableCards
            title={'Todays Trending'}
            subTitle={date}
            pagination={true}
            actions={carats.map(carat => {
                return { action: () => onCaratSelected(carat) }
            })}
            images={carats.map(carat => {
                return {
                    src: carat.imageUrl,
                    alt: carat.name
                }
            })}
            textOverlays={carats.map(carat => `$${carat.price}`)}
            details={carats.map(carat => {
                return {
                    heading: carat.name,
                    subHeading: 'Sub heading',
                    onButtonClick: () => quickAddToCart(carat),
                    onButtonText: 'Add to cart',
                    mediumBtnState: false
                    
                }
            })}
        />
    );
};

export default TodaysTrending;