import React, { useState, useEffect } from 'react'
import Card from './Card'

import './GridView.css'

const GridView = ({ title, numberOfColumns, getGridItems, history }) => {

    const [gridItems, setGridItems] = useState([])

    useEffect(() => {
        // let grid view load it's own grid items when it's needed (on mount)
        getGridItems().then(gridItems => {
            setGridItems(gridItems);
        })
    }, [getGridItems])

    if (gridItems.length > 0) {
        return (
            <div className="row grid-view-container">
                <h1 className="title">{title}</h1>
                {gridItems.map((gridItem) => {
                    return (
                        <div key={gridItem.id} className={`col-${12 / numberOfColumns}`}>
                            <Card
                                onClick={() => gridItem.onClick(history)}
                                imageSrc={gridItem.imageUrl}
                                imageAlt={gridItem.imageAlt}
                                textOverlay={gridItem.textOverlay}
                                caption={gridItem.caption}
                            />
                        </div>
                    )
                })}
            </div>
        )
    } else {
        // TODO: render some progress UI instead of nothing below
        return null; // Don't render anything if we don't have the carat yet from back end
    }
}

export default GridView
