import React from 'react';
import SwipeableCards from '../../sub-components/SwipeableCards';

const RelatedProducts = ({ date, carats, onCaratSelected, quickAddToCart }) => {

    return (
        <SwipeableCards
            title={'Related products'}
            seeAll={"RelatedProducts"}
            slidesPerView={3}
            pagination={false}
            actions={carats.map(carat => {
                return { action: () => onCaratSelected(carat) }
            })}
            images={carats.map(carat => {
                return {
                    src: carat.imageUrl,
                    alt: carat.name
                }
            })}
            textOverlays={carats.map(carat => `$ ${carat.price}`)}
            captions={carats.map(carat => carat.name)}
        />
    );
};

export default RelatedProducts;
