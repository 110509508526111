import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Card from '../sub-components/Card'

const ChangeCard = (props) => {

    const [show, setShow] = useState(true);
    // TODO: Implement the custom message feature
    // eslint-disable-next-line no-unused-vars
    const history = useHistory()

    const handleClose = () => {
        setShow(false)
        props.setShowCardChange(false)
    }

    const handleNewSelectedCarat = (carat) => {
        history.push(`./sendgift?id=${carat.id}`)
        props.setShowCardChange(false)
        setShow(false)
    }

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Array.from(props.allPurchasedCarats.entries()).map((entry, qty) => {
                        const carat = entry[0]
                        return (
                            <div className='mt-4'>
                                <Card
                                    key={carat.id}
                                    imageSrc={carat.imageUrl}
                                    imageAlt={carat.name}
                                    detail={{
                                        heading: carat.name,
                                        subHeading: 'Special Design',
                                        subSubHeading: 'Send it to your loved ones',
                                        onButtonClick: () => handleNewSelectedCarat(carat),
                                        buttonText: 'Select',
                                        mediumBtnSize: true
                                    }}
                                />
                            </div>
                        )
                    })}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangeCard
