import React from 'react'
import SwiperCore, { Navigation, EffectCoverflow } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom'
import SwipeableCards from '../../sub-components/SwipeableCards';

import sports from '../../../dummy_data/carats/sports.json'
import recommended from '../../../dummy_data/carats/recommended.json'
import topCoupons from '../../../dummy_data/coupons/topCoupons.json'

import './couponSelector.scss'
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css"
import 'swiper/swiper.scss'

const CouponSelector = (props) => {

    const { gift, setGift, gifts, setGifts } = props

    const addToCouponCart = (coupon) => {
        const newGift = {
            vault: {
                coupons: [...gift.vault.coupons, coupon],
                media: gift.vault.media,
                isNFT: gift.vault.isNFT
            },
            carat: gift.carat
        }
        setGift(newGift)
        setGifts(gifts.map(oldGift => oldGift.carat.id === gift.carat.id ? newGift : oldGift))
    }

    const removeFromCouponCart = (coupon) => {
        const newGift = {
            vault: {
                coupons: gift.vault.coupons.filter(element => element !== coupon),
                media: gift.vault.media,
                isNFT: gift.vault.isNFT
            },
            carat: gift.carat
        }
        setGift(newGift)
        setGifts(gifts.map(oldGift => oldGift.carat.id === gift.carat.id ? newGift : oldGift))
    }

    SwiperCore.use([Navigation, EffectCoverflow])

    return (
        <>
            {
                <div className="margin-top-override">
                    <SwipeableCards
                        title={'Recommended'}
                        slidesPerView={3.2}
                        couponThumbnail={true}
                        actions={recommended.map(coupon => {
                            return { action: () => addToCouponCart(coupon) }
                        })}
                        images={recommended.map((coupon, idx) => {
                            return {
                                src: coupon.logo,
                                alt: coupon.brand_name
                            }
                        })}
                    />

                    <SwipeableCards
                        title={'Sports'}
                        slidesPerView={3.2}
                        couponThumbnail={true}
                        actions={sports.map(coupon => {
                            return { action: () => addToCouponCart(coupon) }
                        })}
                        images={sports.map((coupon, idx) => {
                            return {
                                src: coupon.logo,
                                alt: coupon.brand_name
                            }
                        })}
                    />

                    <SwipeableCards
                        title={'Top'}
                        slidesPerView={3.2}
                        couponThumbnail={true}
                        actions={topCoupons.map(coupon => {
                            return { action: () => addToCouponCart(coupon) }
                        })}
                        images={topCoupons.map((coupon, idx) => {
                            return {
                                src: coupon.logo,
                                alt: coupon.brand_name
                            }
                        })}

                    />
                </div>
            }
            <p className="discount-message-style" style={{ margin: '25px 0' }}>25% off $140 or More on Purchases</p>

            <>
                <h3 className='heading-style'>Coupons Selected: </h3>
                <div>
                    <Swiper style={{ zIndex: 0 }}
                        spaceBetween={20}
                        centeredSlides={false}
                        slidesPerView={3.2}
                        pagination={true}>
                        <>
                            {Array.from(gift.vault.coupons.values()).map((item) => {
                                return (
                                    <SwiperSlide>
                                        <div className='coupon-and-trash-container'>
                                            <img className="img-fluid" src={item.logo} alt={item.brand_name} />
                                            <div onClick={() => removeFromCouponCart(item)}>
                                                <img src='../../icons/trash-icon.svg' alt='Delete coupon' />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </>
                    </Swiper>
                </div>
            </>
            <Link to={`/purchased`}>
                <button className="primary-button-style bottom-positioning" onClick={props.setVaultOpen(true)}>Back to Vault</button>
            </Link>

        </>

    )
}
export default CouponSelector
