import React, { useState, useEffect } from 'react'
import './sendGift.css'
import EditableLabel from 'react-editable-label'
import ChangeCard from './ChangeCard'
import SentConfirmationModal from './SentConfirmationModal'

const SendGift = ({ getCarat, allPurchasedCarats, cart }) => {

    const [carat, setCarat] = useState(null)
    const [caratLabel, setCaratLabel] = useState(carat ? carat.name : "")
    const [showCardChange, setShowCardChange] = useState(false)
    const [receiverDetails, setReceiverDetails] = useState({
        name: '',
        email: '',
        message: ''
    })
    const [showSendConfirm, setShowSendConfirm] = useState(false)

    const addReceiverDetailsToState = (e) => {
        setReceiverDetails({ ...receiverDetails, [e.target.name]: e.target.value })
    }

    var receiverDetailsSize = receiverDetails.email.length

    const changeClicked = () => {
        setShowCardChange(true)
    }

    const shareBtnClicked = () => {
        setShowSendConfirm(true)
    }

    useEffect(() => {
        getCarat().then(carat => {
            setCarat(carat)
            setCaratLabel(carat.name)
        })
        
        localStorage.receiverState &&
            setReceiverDetails(JSON.parse(localStorage.receiverState))
    }, [getCarat])

    useEffect(() => {
        localStorage.receiverState = JSON.stringify((receiverDetails))
    }, [receiverDetails])

    if (carat) {
        return (
            <>

                <img src={carat.imageUrl} alt={carat.name} width="100%" />

                <EditableLabel
                    initialValue={caratLabel}
                    save={value => setCaratLabel(value)}
                    labelClass={'editable-label-style'}
                />

                <div className="flex-container">
                    <p>Special Design</p>
                    <button onClick={changeClicked}>Change Carat</button>
                </div>

                {showCardChange &&
                    <ChangeCard
                        setShowCardChange={setShowCardChange}
                        cart={cart}
                        allPurchasedCarats={allPurchasedCarats}
                        setCaratLabel={setCaratLabel}
                    />}

                <div className="form-container">
                    {receiverDetailsSize > 0 ?
                        <form>
                            <input
                                name="name"
                                type="name"
                                onChange={addReceiverDetailsToState}
                                placeholder={receiverDetails.name}
                            />
                            <input
                                name="email"
                                type="email"
                                onChange={addReceiverDetailsToState}
                                placeholder={receiverDetails.name}
                            />
                            <textarea
                                name="message"
                                onChange={addReceiverDetailsToState}
                                placeholder={receiverDetails.message}
                                maxLength="500"
                            />
                        </form>
                        :
                        <form>
                            <input
                                name="name"
                                type="name"
                                onChange={addReceiverDetailsToState}
                                placeholder="Receiver's name"
                            />
                            <input
                                name="email"
                                type="email"
                                onChange={addReceiverDetailsToState}
                                placeholder="Receiver's email"
                            />
                            <textarea
                                name="message"
                                onChange={addReceiverDetailsToState}
                                maxLength="500"
                                placeholder={'Add a message... \n\n\n\n500 words'}
                            />
                        </form>
                    }
                </div>

                <div className="button-container">
                    <button className="primary-button-style" onClick={() => shareBtnClicked()}>Send</button>
                </div>

                {showSendConfirm &&
                    <SentConfirmationModal
                        setShowSendConfirm={setShowSendConfirm}
                        receiverDetails={receiverDetails} />}
            </>
        )
    } else {
        // TODO: render some progress UI instead of nothing below
        return null; // Don't render anything if we don't have the carat yet from back end
    }
}

export default SendGift
