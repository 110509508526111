import React, { useState, useEffect } from 'react'
import Card from '../../sub-components/Card'
import ActionStrip from '../../sub-components/ActionStrip'
import RelatedProducts from './RelatedProducts'

import './SingleProductDetails.css'

const SingleProductDetails = ({
    getCarat,
    addToCartClicked,
    getRelatedCarats,
    history,
    onCaratSelected
}) => {
    const [qty, setQty] = useState(1)
    const [carat, setCarat] = useState(null)
    const [relatedCarats, setRelatedCarats] = useState([])

    useEffect(() => {
        getCarat().then(carat => setCarat(carat))
        getRelatedCarats().then(relatedCarats => setRelatedCarats(relatedCarats))
    }, [getCarat, getRelatedCarats])

    const addCCsToCart = [
        { iconSrc: './icons/minus-icon.svg', onTouch: () => { setQty(qty > 0 ? qty - 1 : 0) } },
        { label: `${qty}` },
        { iconSrc: './icons/plus-icon.svg', onTouch: () => { setQty(qty + 1) } },
        { buttonText: 'ADD TO CART', onTouch: () => addToCartClicked(history, carat, qty) }
    ]

    if (carat) {
        return (
            <>
                <div className="cc-frame-container">
                    <img className="carat-frame" src={carat.frameUrl} alt={carat.caption} />
                </div>

                <Card
                    imageSrc={carat.imageUrl}
                    imageAlt={carat.imageAlt}
                    detail={{
                        heading: carat.caption,
                        subHeading: 'Sub heading',
                        subSubHeading: `$ ${carat.price}`
                    }}
                />

                <ActionStrip touchables={addCCsToCart} />

                {
                    relatedCarats ?
                        <RelatedProducts
                            carats={relatedCarats}
                            onCaratSelected={(carat) => onCaratSelected(history, carat)}
                        /> :
                        null /* TODO: render some progress component instead of null */
                }
            </>
        )
    } else {
        // TODO: render some progress UI instead of nothing below
        return null; // Don't render anything if we don't have the carat yet from back end
    }
}


export default SingleProductDetails
