import React from 'react'
import { Link } from 'react-router-dom'

const ProfileLink = ({ selected }) => {
    return (
        <Link to="/profile">
            <svg width="28" height="26" viewBox="0 0 28 26" fill={selected ? "#565656" : "#A3A3A3"} xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.3636 8.72741C16.3636 9.88471 15.9039 10.9946 15.0855 11.813C14.2672 12.6313 13.1573 13.091 12 13.091C10.8427 13.091 9.73277 12.6313 8.91443 11.813C8.09609 10.9946 7.63635 9.88471 7.63635 8.72741C7.63635 7.5701 8.09609 6.46019 8.91443 5.64185C9.73277 4.82351 10.8427 4.36377 12 4.36377C13.1573 4.36377 14.2672 4.82351 15.0855 5.64185C15.9039 6.46019 16.3636 7.5701 16.3636 8.72741ZM14.1818 8.72741C14.1818 9.30606 13.9519 9.86101 13.5428 10.2702C13.1336 10.6794 12.5786 10.9092 12 10.9092C11.4213 10.9092 10.8664 10.6794 10.4572 10.2702C10.048 9.86101 9.81817 9.30606 9.81817 8.72741C9.81817 8.14875 10.048 7.5938 10.4572 7.18463C10.8664 6.77546 11.4213 6.54559 12 6.54559C12.5786 6.54559 13.1336 6.77546 13.5428 7.18463C13.9519 7.5938 14.1818 8.14875 14.1818 8.72741Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.37273 0 0 5.37273 0 12C0 18.6273 5.37273 24 12 24C18.6273 24 24 18.6273 24 12C24 5.37273 18.6273 0 12 0ZM2.18182 12C2.18182 14.28 2.95964 16.3789 4.26327 18.0458C5.1788 16.8435 6.35989 15.8692 7.71429 15.1989C9.06869 14.5286 10.5597 14.1805 12.0709 14.1818C13.5625 14.1804 15.0348 14.5195 16.3756 15.1732C17.7163 15.827 18.8901 16.7781 19.8076 17.9542C20.7529 16.7145 21.3893 15.2675 21.6642 13.733C21.9392 12.1985 21.8448 10.6206 21.3889 9.1298C20.933 7.63902 20.1286 6.27823 19.0423 5.16004C17.9561 4.04185 16.6192 3.19839 15.1422 2.69946C13.6653 2.20054 12.0908 2.06048 10.5489 2.29088C9.00711 2.52128 7.54232 3.11552 6.27575 4.02442C5.00918 4.93333 3.97725 6.13077 3.26534 7.51767C2.55343 8.90457 2.18202 10.4411 2.18182 12ZM12 21.8182C9.74613 21.8216 7.56029 21.0462 5.81236 19.6233C6.51592 18.6161 7.45237 17.7937 8.54203 17.2262C9.63169 16.6587 10.8423 16.3628 12.0709 16.3636C13.2842 16.3627 14.4802 16.6512 15.5596 17.2052C16.6389 17.7592 17.5706 18.5628 18.2771 19.5491C16.5156 21.0182 14.2937 21.8214 12 21.8182Z" />
            </svg>
        </Link>
    )
}

export default ProfileLink
