import React from 'react'
import { Link } from 'react-router-dom'

import '../styles/login.css'

const Login = () => {

    const loginObjects = {
        header: 'COSMIC CARAT',
        subHeader: 'Welcome',
        loginRedo: 'Hello Login Again',
        imageSrc: '/icons/app-icon.svg',
        forgotPass: 'Forgot Password?',
        signIn: 'Sign In'
    }

    return (

            <div className="global-container-overwrite">
                <div className="screen-container text-center">
                    <div className="skip-link-container">
                        <Link to="/shop">Skip &gt;&gt;</Link>
                    </div>

                    <img className="img__styling" src="/icons/app-icon.svg" alt="Cosmic Carat Logo" />

                    <p className="header-style">{loginObjects.header}</p>
                    <p className="welcome-sub-header-style">{loginObjects.subHeader}</p>
                    <p className="action-sub-sub-header">{loginObjects.loginRedo}</p>

                    <input type="email" placeholder="Enter email" className="input-border" required />
                    
                    <input type="password" placeholder="Password" className="input-border" required />

                    <Link to='/signup'>
                        <p className="forgot-password-style">{loginObjects.forgotPass}</p>
                    </Link>

                    <div className="submit__btn__container  mt-3">
                        <div className="primary-button-style sign-in-button-format"><p>{loginObjects.signIn}</p></div>
                    </div>

                    <div className="social-login">
                        <p className="mt-3">Or login using</p>
                        <div className="horizontal-container">
                            <img className="container-item" src='/icons/facebook-icon-temp.svg' alt='facebook login button'/>
                            <img className="container-item" src='/icons/google-icon-temp.svg' alt='google login button'/>
                        </div>
                    </div>

                    <div className="link-to-sign-up">
                        <p>Don't have an account? <a href="/signup" style={{ fontWeight: "bold" }}> Click here</a></p>
                    </div>
                </div>
            </div>

    );
}

export default Login











