import React, { useState, useEffect, useRef } from 'react'
import './App.scss'
import './components/styles/buttons.scss'

import { QueryClient, QueryClientProvider } from 'react-query'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Modal } from 'react-bootstrap'

import SplashScreen from './components/SplashScreen/SplashScreen'
import Shop from './components/Shop/ShopHome/Shop'
import Login from './components/sub-components/Login'
import Signup from './components/sub-components/Signup'
import Navbar from './components/Navbars/HeaderNav/Navbar'
import FooterNav from './components/Navbars/FooterNav/FooterNav'

import Cart from './components/Cart/CartHome/Cart'
import CouponSelector from './components/Shop/CouponSelector/couponSelector'
import Checkout from './components/Cart/Checkout/Checkout'
import PaymentSummary from './components/Payment/PaymentSummary'
import OrderCompleted from './components/Payment/OrderCompleted/OrderCompleted'
import Purchased from './components/Cart/Purchased/Purchased'
import Profile from './components/Profile/Profile'
import SendGift from './components/SendGift/SendGift'
import SearchBar from './components/sub-components/SearchBar'
import SingleProductDetails from './components/Shop/SingleProductDetails/SingleProductDetails'
import GridView from './components/sub-components/GridView'

import { getItems, getRelatedItems } from './BackendAPI'

const App = () => {
  const [pageTitle, setPageTitle] = useState('')

  const [carats, setCarats] = useState(new Map())

  const [cart, setCart] = useState(new Map())

  const [allPurchasedCarats, setAllPurchasedCarats] = useState(new Map())

  const [allGifts, setAllGifts] = useState([])

  const [vaultOpen, setVaultOpen] = useState(false)

  const [gift, setGift] = useState({
    vault: {
      coupons: [],
      media: [],
      isNFT: false
    },
    carat: null
  })


  // TODO: save in local storage or session cookie
  const [userProfile, setUserProfile] = useState({
    name: '',
    biography: '',
    telephone: '',
    imgUrl: ''
  })

  const [couponCart, setCouponCart] = useState(new Set())
  const [showHeaderNavBar, setShowHeaderNavBar] = useState(false)
  const [showFooterNavBar, setShowFooterNavBar] = useState(false)
  const [showSearchBar, setShowSearchBar] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const [modalOptions, setModalOptions] = useState({
    icon: '/icons/app-icon.svg',
    message: 'Welcome to Cosmic Carat!',
    onClickPrompt: 'Get started now',
    onClick: () => setShowModal(false),
    onClose: () => { }
  })

  const decrementCart = (carat, qty) => {
    if (cart.get(carat.id) > 1) {
      setCart(previousCart => new Map(previousCart.set(carat.id, cart.get(carat.id) - qty)))
    } else {
      cart.delete(carat.id)
      setCart(new Map(cart))
    }
  }

  const incrementCart = (carat, qty) => {
    if (cart.get(carat.id)) {
      setCart(previousCart => new Map(previousCart.set(carat.id, cart.get(carat.id) + qty)))
    } else {
      cart.set(carat.id, qty)
    }
  }

  const addToCartClicked = (history, carat, qty) => {
    incrementCart(carat, qty)
    setModalOptions({
      icon: './icons/app-icon.svg',
      message: `Added ${qty} ${carat.name}(s) to cart!`,
      onClickPrompt: 'Back to shop',
      onClick: () => {
        setShowModal(false)
        history.goBack()
      },
      onClose: () => { }
    })
    setShowModal(true)
  }

  const getTotalPrice = (order) => {
    let totalPrice = 0
    order.forEach((qty, caratId) => {
      totalPrice += qty * carats.get(caratId).price
    })
    return totalPrice
  }

  const configureScreen = (header, searchBar, footer, title) => {
    setShowHeaderNavBar(header)
    setShowSearchBar(searchBar)
    setShowFooterNavBar(footer)
    setPageTitle(title)
  }

  const onCaratSelected = (history, carat) => {
    history.push(`./caratdetails?id=${carat.id}`)
  }


  // update state from local storage
  useEffect(() => {
    gift.vault.coupons && setCouponCart(gift.vault.coupons)
  }, [gift.vault.coupons])


  useEffect(() => {
    if (localStorage.cart) {
      setCart(new Map(JSON.parse(localStorage.cart)))
    }

    if (localStorage.allPurchasedCarats) {
      setAllPurchasedCarats(new Map(JSON.parse(localStorage.allPurchasedCarats)))
    }

    if (localStorage.carats) {
      setCarats(new Map(JSON.parse(localStorage.carats)))
    }

    if (localStorage.gift) {
      setGift(JSON.parse(localStorage.gift))
    }

    if (localStorage.allGifts) {
      setAllGifts(JSON.parse(localStorage.allGifts))
    }

    if (localStorage.userProfile) {
      setUserProfile(JSON.parse(localStorage.userProfile))
    }
  }, [])


  // update local storage from state (when cart changes)
  useEffect(() => {
    localStorage.cart = JSON.stringify(Array.from(cart.entries()))
    return () => localStorage.cart = JSON.stringify(Array.from(cart.entries()))
  }, [cart])

  // update local storage from state (when all purchased carats changes)
  useEffect(() => {
    localStorage.allPurchasedCarats = JSON.stringify(Array.from(allPurchasedCarats.entries()))
    return () => localStorage.allPurchasedCarats = JSON.stringify(Array.from(allPurchasedCarats.entries()))
  }, [allPurchasedCarats])

  // update local storage from state (when carats changes)
  useEffect(() => {
    localStorage.carats = JSON.stringify(Array.from(carats.entries()))
    return () => localStorage.carats = JSON.stringify(Array.from(carats.entries()))
  }, [carats])

  // update local storage from state (when gift changes)
  useEffect(() => {
    localStorage.gift = JSON.stringify(gift)
    return () => localStorage.gift = JSON.stringify(gift)
  }, [gift])

  // update local storage from state (when all gifts changes)
  useEffect(() => {
    localStorage.allGifts = JSON.stringify(allGifts)
    return () => localStorage.allGifts = JSON.stringify(allGifts)
  }, [allGifts])

  // update local storage from state (when user profile changes)
  useEffect(() => {
    localStorage.userProfile = JSON.stringify(userProfile)
    return () => localStorage.userProfile = JSON.stringify(userProfile)
  }, [userProfile])

  const editableModalInputRef = useRef(null)

  const client = new QueryClient()

  return (
    <QueryClientProvider client={client}>
      <div>
        <div className={showHeaderNavBar || showSearchBar ? "top" : ""}>
          {showHeaderNavBar && <Navbar pageTitle={pageTitle} />}
          {
            /* TODO: figure out another way to get history for future SSR */
            showSearchBar && <SearchBar history={window.history} />
          }
        </div>

        <Router>

          <div className="middle">

            <Switch>

              <Route path="/" exact render={() => <SplashScreen setPageTitle={setPageTitle} />} />

              <Route
                path='/login'
                render={() => {
                  configureScreen(false, false, false, "")
                  return <Login />
                }}
              />

              <Route
                path='/signup'
                render={() => {
                  configureScreen(false, false, false, "")
                  return <Signup />
                }}
              />

              <Route
                path="/shop"
                render={({ history }) => {
                  configureScreen(false, true, true, "")

                  return <Shop
                    history={history}
                    onCaratSelected={onCaratSelected}
                    setCarats={setCarats}
                    setCart={setCart}
                    setModalOptions={setModalOptions}
                    setShowModal={setShowModal}
                  />
                }}
              />

              <Route
                path="/seeall"
                render={(props) => {
                  const { location: { search }, history } = props
                  const params = Object.fromEntries(new URLSearchParams(search))
                  configureScreen(true, false, false, "")
                  return (
                    <GridView
                      title={params.title}
                      numberOfColumns={params.numOfCol}
                      history={history}
                      getGridItems={async () => await getItems(params.ids, params.type)}
                    />
                  )
                }}
              />

              <Route
                path='/caratdetails'
                render={(props) => {
                  configureScreen(true, false, false, "")
                  const { location: { search }, history } = props
                  const params = Object.fromEntries(new URLSearchParams(search))
                  return <SingleProductDetails
                    addToCartClicked={addToCartClicked}
                    getCarat={async () => {
                      const items = await getItems([params.id], 'carat')
                      return items[0]
                    }}
                    history={history}
                    onCaratSelected={onCaratSelected}
                    getRelatedCarats={async () => await getRelatedItems(params.id, 'carat')}
                  />
                }}
              />

              <Route
                path="/cart"
                render={({ history }) => {
                  configureScreen(true, false, true, "Cart")
                  return <Cart
                    history={history}
                    carats={carats}
                    setCarats={setCarats}
                    cart={cart}
                    setCart={setCart}
                    decrementCart={decrementCart}
                    incrementCart={incrementCart}
                    setModalOptions={setModalOptions}
                    setShowModal={setShowModal}
                  />
                }}
              />

              <Route
                path="/checkout"
                render={({ history }) => {
                  configureScreen(true, false, false, "Checkout")
                  return <Checkout
                    history={history}
                    carats={carats}
                    cart={cart}
                    getTotalPrice={getTotalPrice}
                    allPurchasedCarats={allPurchasedCarats}
                    setAllPurchasedCarats={setAllPurchasedCarats}
                    subHeading="Payment"
                    buttonTitle="Add a new card"
                  />
                }}
              />

              <Route
                path="/purchased"
                render={({ history }) => {
                  configureScreen(true, false, true, 'Purchased')
                  return <Purchased
                    gift={gift}
                    setGift={setGift}
                    gifts={allGifts}
                    setGifts={setAllGifts}
                    history={history}
                    carats={carats}
                    allPurchasedCarats={allPurchasedCarats}
                    setAllPurchasedCarats={setAllPurchasedCarats}
                    couponCart={couponCart}
                    vaultOpen={vaultOpen}
                    setVaultOpen={setVaultOpen}
                  />
                }}
              />

              <Route
                path="/ordercompleted"
                render={() => {
                  configureScreen(true, false, false, "")
                  return <OrderCompleted
                    carats={carats}
                    cart={cart}
                    setCart={setCart}
                    getTotalPrice={getTotalPrice}
                    orderNumber={"Order #: 111222333"} //TODO: generated from the back end after successful checkout
                  />
                }}
              />

              <Route
                path="/profile"
                render={() => {
                  configureScreen(true, false, true, "Profile")
                  return <Profile
                    setModalOptions={setModalOptions}
                    setShowModal={setShowModal}
                    userProfile={userProfile}
                    setUserProfile={setUserProfile}
                  />
                }}
              />

              <Route
                path="/couponselector"
                render={() => {
                  configureScreen(true, true, false, "Coupon Selector")
                  return <CouponSelector
                    gift={gift}
                    setGift={setGift}
                    gifts={allGifts}
                    setGifts={setAllGifts}
                    couponCart={couponCart}
                    setCouponCart={setCouponCart}
                    setVaultOpen={setVaultOpen}
                  />
                }}
              />

              <Route path="/paymentsummary" render={() => <PaymentSummary />} />

              <Route
                path="/sendgift"
                render={(props) => {
                  configureScreen(true, false, false, 'Send Gift')
                  const { location: { search } } = props
                  const params = Object.fromEntries(new URLSearchParams(search))
                  return <SendGift
                    getCarat={async () => {
                      const items = await getItems([params.id], 'carat')
                      return items[0]
                    }}
                    allPurchasedCarats={allPurchasedCarats}
                    cart={cart}
                  />
                }}
              />

            </Switch>
          </div>

          <div className="bottom">
            {showFooterNavBar && <FooterNav />}
          </div>
        </Router>

        {/* editable: {
        type: text | telephone | file | email | etc,
        label: ?string,
        placeholder: ?string,
        onChange: (event) => void,
        onSubmit: (input.current.value) => void
      } */}

        <Modal show={showModal} onHide={modalOptions.onClose} centered>
          <Modal.Body className="text-center">
            <img src={modalOptions.icon} alt={modalOptions.message} width={'50%'} />
            {
              modalOptions.editable ?
                <div>
                  {modalOptions.editable.label && <label >{modalOptions.editable.label}</label>}
                  <input
                    ref={editableModalInputRef}
                    className="editable-modal-input"
                    type={modalOptions.editable.type}
                    placeholder={modalOptions.editable.placeholder}
                    onChange={(event) => {
                      modalOptions.editable.onChange(event)
                    }}
                  />
                </div> :
                <p>{modalOptions.message}</p>
            }
            <button
              className="primary-button-style"
              onClick={
                modalOptions.editable ?
                  () => modalOptions.editable.onSubmit(editableModalInputRef.current.value) :
                  modalOptions.onClick
              }
            >
              {modalOptions.onClickPrompt}
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </QueryClientProvider>
  )

}

export default App
