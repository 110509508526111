import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { FacebookIcon, FacebookShareButton, InstapaperIcon, InstapaperShareButton } from "react-share";
import Card from '../../sub-components/Card';
import './purchased.css';

import Vault from '../../Vault/Vault'
import { requestGiftUrl } from '../../../BackendAPI'

const Purchased = ({ gift, setGift, gifts, setGifts, history, carats, allPurchasedCarats, couponCart, vaultOpen, setVaultOpen }) => {

    const [giftUrl, setGiftUrl] = useState("cosmiccarat.com")

    const [show, setShow] = useState(false)

    const [checkedGiftCaratId, setCheckedGiftCaratId] = useState(0)

    const [caratSelectedID, setCaratSelected] = useState(null)

    const onGiftSend = () => {
        setShow(false)
        history.push(`./sendgift`)
    }

    const onShareByEmailClick = () => {
        history.push(`./sendgift?id=${caratSelectedID}`)
    }

    const handleShowModal = async (caratId) => {
        const gift = gifts.find(gift => gift.carat?.id === caratId)
        const giftUrl = await requestGiftUrl(gift)
        setGiftUrl(giftUrl)
        setCaratSelected(caratId)
        setShow(true)
    }

    const openVaultClicked = () => {
        setVaultOpen(true)
    }

    useEffect(() => {
        setGifts(previousGifts =>
            Array.from(allPurchasedCarats.entries()).map((entry, index) => {
                const caratId = entry[0]
                const carat = carats.get(caratId)
                if (previousGifts[index]) {
                    return { 
                        ...previousGifts[index],
                        carat
                    }
                } else {
                    return {
                        vault: {
                            coupons: [],
                            media: [],
                            isNFT: false
                        },
                        carat
                    }
                }
            })
        )

    }, [allPurchasedCarats, carats, setGifts])

    const checkedGift = gifts.find(gift => gift.carat?.id === checkedGiftCaratId)
    const numberOfVaultItems = checkedGift ?
        checkedGift.vault?.coupons.length + checkedGift.vault?.media.length :
        0

    return (
        <>
            <div>
                {Array.from(allPurchasedCarats.entries()).map(entry => {
                    const caratId = entry[0]
                    const carat = carats.get(caratId)
                    const qty = entry[1]
                    if (carat) {
                        return <Card
                            key={carat.id}
                            imageSrc={carat.imageUrl}
                            imageAlt={carat.name}
                            detail={{
                                heading: `${carat.name} (x${qty})`,
                                subSubHeading: 'Send it to your loved ones',
                                subSubSubHeading: `${qty} carats left to send`,
                                onButtonClick: () => handleShowModal(caratId),
                                buttonText: 'Send',
                                mediumBtnSize: true
                            }}
                            selectable={{
                                onSelected: (checked, caratId) => {
                                    setGift(gifts.find(gift => gift.carat?.id === caratId))
                                    setCheckedGiftCaratId(caratId)
                                },
                                checked: checkedGiftCaratId === carat.id,
                                caratId: carat.id
                            }}
                        />
                    } else {
                        // TODO: render some progress UI instead of nothing below
                        return null; // Don't render anything if we don't have the carat yet from back end
                    }
                })}
            

            <Modal show={show} dialogClassName='modal-bottom-position' onHide={() => setShow(false)}>
                <Modal.Header style={{ borderBottom: 'none' }}><p className="modal-header-style">Send Cosmic Carat Gift</p></Modal.Header>
                <Modal.Body className="share-modal-style">
                    <FacebookShareButton
                        url={giftUrl}
                        quote={"Cosmic Carat - The greatest gift of all"}
                        hashtag="#cosmiccarat"
                        onClick={() => onGiftSend()}>

                        <FacebookIcon size={36} round={true} />
                    </FacebookShareButton>

                    <i className="fas fa-envelope fa-lg icon__size" round={true} onClick={() => onShareByEmailClick()}></i>

                    <InstapaperShareButton
                        url={giftUrl}
                        quote={"Quote here"}
                        hashtag="#cosmiccarat"
                        onClick={() => onGiftSend()}>

                        <InstapaperIcon size={36} round={true} />
                    </InstapaperShareButton>
                </Modal.Body>
            </Modal>
            </div>
            {vaultOpen &&
                <Vault
                    gift={gift}
                    setGift={setGift}
                    gifts={gifts}
                    setGifts={setGifts}
                    setVaultOpen={setVaultOpen}
                    couponCart={couponCart}
                />
            }

            <div className="sticky-at-the-bottom">
                {
                    checkedGift && <div className="button-container">
                        <button className="primary-button-style" onClick={openVaultClicked}>
                            Open Vault ({numberOfVaultItems} items)
                        </button>
                    </div>
                }
            </div>
        </>
    )
}

export default Purchased
