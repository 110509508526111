import React from 'react'
import { useHistory } from 'react-router'
import './Navbar.scss'

function Navbar(props) {
    const history = useHistory();

    return (
        <div className="nav-container">
            <div className="stay-inside">
                <div className="back-button">
                    <img className="icon-small" src="images/left-arrow-chevron.svg" alt="Go back" onClick={() => history.goBack()} />
                </div>
                <div className="horizontal-center">
                    <h1 className="page-title">{props.pageTitle}</h1>
                </div>
            </div>
        </div>
    )
}

export default Navbar
