import React, { useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import { Modal } from 'react-bootstrap'
import FormInput from '../../sub-components/FormInput'

import './creditCardForm.css'
import ReactCreditCard from 'react-credit-cards'

function CreditCardForm(props) {
    const [newCreditCardDetails, setNewCreditCardDetails] = useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    })

    const handleInputChange = (e) => {
        setNewCreditCardDetails({ ...newCreditCardDetails, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        props.setShowCreditCardForm(false)
    }

    const onAddCard = async () => {
        await props.onAddNewCard(newCreditCardDetails)
    }

    return (
        <>
            <Modal show={props.showCreditCardForm} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Card</Modal.Title>
                </Modal.Header>
                <ReactCreditCard
                    className="card-size"
                    key={newCreditCardDetails.number}
                    number={newCreditCardDetails.number}
                    name={newCreditCardDetails.name}
                    expiry={newCreditCardDetails.expiry}
                    cvc={newCreditCardDetails.cvc}
                />
                <Modal.Body>
                    <div>
                        <div>
                            <FormInput
                                label='Name on card'
                                type='text'
                                name='name'
                                placeholder='Full Name'
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <FormInput
                                label='Card Number'
                                type='tel'
                                name='number'
                                placeholder='Card Number'
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="expiry-and-cvc-container">
                            <FormInput
                                label="Expiry"
                                type='text'
                                name='expiry'
                                placeholder='MM/YY'
                                onChange={handleInputChange}
                            />

                            <FormInput
                                label="CVC"
                                type='tel'
                                name='cvc'
                                placeholder='CVC'
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <div>
                        <button className="add-card-button-style" onClick={onAddCard}>
                            ADD CARD
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreditCardForm