import React from 'react'
import { Link } from 'react-router-dom'
import './splashScreen.css'

const SplashScreen = (props) => {
    return (
        <div className='global-container-overwrite'>
            <div className="splash-container">
                <div className="splash-header-container">
                    <div className="col col-sm-8 col-md-4">
                        <h4 className="header-font">Buy a gift for a <br /> loved one</h4>
                    </div>
                    <div className="col col-sm-4 col-md-4 offset-md-4">
                        <div className="app-icon-position-style">
                            <img src='/icons/app-icon.svg' className="app-icon-position-style" alt='Cosmic Carat Logo' />
                        </div>
                    </div>
                </div>
                <img className='splash-image-style' src='/images/SplashScreen__img.svg' alt="Splash Screen Logo" />
                <div className="splashscreen-button-container">
                    <Link to='/login'>
                        <img src="/images/splashScreen-button.svg" alt="Let's get started button" />
                        <p>LET'S GET STARTED</p>
                    </Link>
                </div>
                <p className="learn-more-text-style">Learn More</p>

            </div>
        </div>




    )
}

export default SplashScreen
