import React, { useState, useEffect } from 'react'
import './checkout.scss'
import ReactCreditCard from 'react-credit-cards'
import CreditCardForm from './CreditCardForm'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Accordion } from 'react-bootstrap'
import Card from '../../sub-components/Card'
import { getCreditCards, addNewCreditCardToBackend, payNow } from '../../../BackendAPI'
import { mergeMaps } from '../../../Util'

const Checkout = ({
    history,
    carats,
    cart,
    getTotalPrice,
    allPurchasedCarats,
    setAllPurchasedCarats,
    subHeading,
    buttonTitle
}) => {
    const [showCreditCardForm, setShowCreditCardForm] = useState(false)
    const [creditCards, setCreditCards] = useState([])

    const handleNewCardButtonClick = () => {
        setShowCreditCardForm(true)
    }

    const addNewCreditCard = async (creditCard) => {
        const response = await addNewCreditCardToBackend(creditCard)
        if (response.success) {
            setCreditCards([creditCard, ...creditCards])
        } else {
            alert("Something went wrong: ", response.errorMessage)
        }
        setShowCreditCardForm(false)
    }

    const handlePayNow = async (cart) => {
        const response = await payNow(cart)
        if (response.success) {
            const newAllPurchasedCarats = mergeMaps(allPurchasedCarats, cart, (qty1, qty2) => qty1 + qty2)
            setAllPurchasedCarats(newAllPurchasedCarats)
            history.replace("./ordercompleted")
        } else {
            alert("Something went wrong: ", response.errorMessage)
        }
    }

    useEffect(() => {
        async function fetchCreditCards() {
            const response = await getCreditCards('76t23ef273tr823r92y3r92g3', 'dfsdf7182et1873tre827t3gr827t3r2')
            setCreditCards(response)
        }

        fetchCreditCards()
    }, [])

    return (
        <>
            <div className="heading-and-button-style">
                <h3>{subHeading}</h3>
                <button onClick={handleNewCardButtonClick} className="add-new-card">
                    <img src="/icons/plus-icon.svg" alt="plus" className="plus-icon" />
                    <p>{buttonTitle}</p>
                </button>
            </div>

            <div className="display__on__small">
                <Swiper
                    className="swiper-container"
                    slidesPerView={1}
                    spaceBetween={0}
                    centeredSlides={true}
                    grabCursor={true}
                    pagination={{
                        "clickable": true
                    }}
                >
                    {creditCards.map(card => {
                        return (
                            <SwiperSlide key={card.number}>
                                <ReactCreditCard
                                    className="card-size"
                                    key={card.number}
                                    number={card.number}
                                    name={card.name}
                                    expiry={card.expiry}
                                    cvc={card.cvc}
                                />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>

            <Accordion className="mt-5">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <p className="accordion-header">Your Cosmic Carats</p>
                    </Accordion.Header>
                    <Accordion.Body>
                        {Array.from(cart.entries()).map((entry) => {
                            const carat = carats.get(entry[0])
                            const qty = entry[1]
                            return <Card
                                key={carat.id}
                                imageSrc={carat.imageUrl}
                                imageAlt={carat.name}
                                detail={{
                                    heading: carat.name,
                                    subSubHeading: `$ ${carat.price} (x${qty})`
                                }}
                            />
                        })}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <div className="charges-summary mt-3">
                <p>Number of Carats</p>
                <p>{cart.size > 0 ? Array.from(cart.values()).reduce((qty1, qty2) => qty1 + qty2, 0) : 0}</p>
            </div>

            <div className="charges-summary">
                <p>Cart Total</p>
                <p>{`$ ${getTotalPrice(cart)}`}</p>
            </div>

            <CreditCardForm
                showCreditCardForm={showCreditCardForm}
                setShowCreditCardForm={setShowCreditCardForm}
                onAddNewCard={addNewCreditCard}
            />

            <div className="button__container">
                <button className="primary-button-style mt-4" onClick={() => handlePayNow(cart)}>Pay Now</button>
            </div>

        </>
    )
}

export default Checkout
