import React from 'react'
import './ActionStrip.scss'
import '../styles/buttons.scss'

// toubables: [{ iconSrc|label|buttonText: string, onTouch: () => void }]

const ActionStrip = ({ touchables, spacingMode }) => {

    // TODO: Pass and use a 'spacingMode' to space out rendable touchable in different ways

    const rendableTouchables = touchables.map(touchable => {
        if (touchable.iconSrc) {
            return <img src={touchable.iconSrc} onClick={touchable.onTouch} alt={touchable.alt} />
        } else if (touchable.label) {
            return <label>{touchable.label}</label>
        } else if (touchable.buttonText) {
            return <button className="primary-button-style" onClick={touchable.onTouch}>{touchable.buttonText}</button>
        } else { // it must be a checkbox
            return <input type="checkbox" onChange={touchable.onTouch} />
        }
    })

    return (
        <div className="action-strip">
            {rendableTouchables.map((rendableTouchable, index) => {
                return <div className='spacing' key={index}>{rendableTouchable}</div>
            })}
        </div>
    )
}

export default ActionStrip
