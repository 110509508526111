import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Card from '../sub-components/Card'
import {Link} from 'react-router-dom'

import './SwipeableCards.css'

const SwipeableCards = ({
    title,
    subTitle,
    link,
    slidesPerView = 1.1,
    pagination,
    actions,
    images,
    textOverlays,
    details,
    captions,
    couponThumbnail,
    primaryCaptionFont
    
}) => {

    return (
        <div style={{ marginTop: '16px', zIndex: '0' }}>
            <div className="swiper-heading">
                <h2 className="swiper-title">
                    {title}
                    {subTitle && <i className="swiper-subtitle">{` ${subTitle}`}</i>}
                </h2>
                {link && <Link to={link.to} className="link-style">{link.text}</Link>}
            </div>
            <Swiper
                slidesPerView={slidesPerView}
                pagination={pagination}
                // Note: {marginBottom: '-24px'} is hacky but can't override Swiper.wrapperClass in React
                // Note: 24px is the inspected height of pagination
                style={pagination ? {paddingBottom: '24px', zIndex: '0'} : {marginBottom: '-24px', zIndex: '0'}}
            >
                <>
                    {images.map((image, index) => {
                        // TODO: don't use index (use image.src insead) as key because -> https://tinyurl.com/noIndexKeys
                        const key = `${index}_swiper_slide`

                        return (
                            <SwiperSlide key={key}>
                                <Card
                                    onClick={actions?.[index].action}
                                    imageSrc={image.src}
                                    imageAlt={image.alt}
                                    textOverlay={textOverlays?.[index]}
                                    caption={captions?.[index]}
                                    detail={details?.[index]}
                                    couponThumbnail={couponThumbnail}
                                    primaryCaptionFont={primaryCaptionFont}
                                    swipeable

                                />
                            </SwiperSlide>
                        )
                    })}
                </>
            </Swiper>
        </div>
    )
}

export default SwipeableCards