import React from 'react'
import './FooterNav.css'
import {  useLocation } from 'react-router-dom'
import CartLink from './Links/CartLink'
import HomeLink from './Links/HomeLink'
import PurchasedLink from './Links/PurchasedLink'
import ProfileLink from './Links/ProfileLink'

const FooterNav = () => {

    const location = useLocation()

    return (
        <>
            <div className="row mr-0 ml-0">
                <ul className="footer__nav__container">
                    <div className="d-flex justify-content-between autoFocus">
                        <li><CartLink selected={location.pathname === '/cart'} /></li>
                        <li><HomeLink selected={location.pathname === '/shop'} /></li>
                        <li><PurchasedLink selected={location.pathname === '/purchased'} /></li>
                        <li><ProfileLink selected={location.pathname === '/profile'} /></li>
                    </div>
                </ul>
            </div>
        </>
    )
}

export default FooterNav
