// @flow

import React from 'react'

// $FlowIgnore[cannot-resolve-module] what in actual the fuck
import './Card.scss'

// $FlowIgnore[cannot-resolve-module]
import '../../components/styles/buttons.scss'

import DetailOptionals from './DetailOptionals'

const DEFAULT_MAX_CAPTION_SIZE = 9

type OnButtonClick = () => void

type OnTouch = (event: SyntheticEvent<HTMLButtonElement>) => void

type Touchable = {
    label: ?string,
    checked: ?boolean,
    srcIcon: ?string,
    onTouch: ?OnTouch
}

type Detail = {
    heading: string,
    smallHeading: ?string,
    subHeading: ?string,
    subSubHeading: ?string,
    touchables: ?Touchable[],
    onButtonClick: ?OnButtonClick,
    buttonText: ?string,
    mediumBtnSize: ?boolean
}

// TODO: see if we can omit caratId - too non-generic for this component
// Option: type OnSelected = (event: SyntheticEvent<HTMLInputElement>) => void
type OnSelected = (checked: boolean, caratId: number) => void

type Selectable = {
    onSelected: OnSelected,
    checked: boolean,
    caratId: number
}

type Props = {
    onClick: ?OnButtonClick,
    imageSrc: string,
    imageAlt: string,
    textOverlay: ?string,
    caption: ?string,
    maxCaptionSize: ?number,
    detail: ?Detail,
    swipeable: ?boolean,
    selectable: ?Selectable,
    couponThumbnail: ?boolean,
    primaryCaptionFont: ?boolean
    // btnSizeMedium: ?mediumBtnSize
}

const Card = ({
    onClick,
    imageSrc,
    imageAlt,
    textOverlay,
    caption,
    maxCaptionSize,
    detail,
    swipeable,
    selectable,
    couponThumbnail, 
    primaryCaptionFont

}: Props): React$Element<any> => {
    const imgColSplit = detail ? "col-5 p-8" : "col-12 p-8"
    const heading = swipeable ? "swipeable-card heading" : "non-swipeable-checkout-card heading"
    const btnSizing = detail?.mediumBtnSize ? "medium-sized-card-button" : "primary-button-style"
    const imgSizing= couponThumbnail ? "img-fluid coupon-img-thumbnail-container" : "img-fluid img-container"
    const captionFont= primaryCaptionFont ? "primary-caption-font" : "secondary-caption-font"
    
    let truncatedCaption
    if (caption) {
        truncatedCaption = caption.length > DEFAULT_MAX_CAPTION_SIZE ?
        (
            maxCaptionSize ?
                `${caption.substring(0, maxCaptionSize)}...` :
                `${caption.substring(0, DEFAULT_MAX_CAPTION_SIZE)}...`
        ) : 
        caption
    }

    return (
        <div className="row p-0">
            <div className={imgColSplit} onClick={onClick}>
                {selectable &&
                    <input
                        type="checkbox"
                        checked={selectable.checked}
                        onChange={(event) => selectable.onSelected(event.target.checked, selectable.caratId)}
                    />
                }
                <img className={imgSizing} src={imageSrc} alt={imageAlt} />
                {textOverlay && <p className={"text-overlay"}>{textOverlay}</p>}
                {truncatedCaption && <p className={captionFont}>{truncatedCaption}</p>}
            </div>

            {detail &&
                <div className={"col-7 pl-0"}>
                    <div onClick={onClick}>
                        <h2 className={heading}>{detail.heading}</h2>
                        <DetailOptionals
                            subHeading={detail.subHeading}
                            subSubHeading={detail.subSubHeading}
                            touchables={detail.touchables}
                            smallHeading={detail.smallHeading}
                        />
                    </div>
                    {detail.onButtonClick &&  
                        <button className={btnSizing} onClick={detail.onButtonClick}>
                            {detail.buttonText ? detail.buttonText : 'Add to cart'}
                        </button>   
                    }
                </div>
            }
        </div >
    )
}

export default Card
