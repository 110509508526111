import React, { useCallback, useEffect } from 'react'

import { Link } from 'react-router-dom'
import './orderCompleted.css'
import { Accordion } from 'react-bootstrap' //This is beta because react-bootstrap is updating its versions. Some elements are still in old version and some are in new version

const OrderCompleted = ({
    carats,
    cart,
    setCart,
    getTotalPrice,
    orderNumber
}) => {

    const purchasedCarats = Array.from(cart.entries())
    const price = getTotalPrice(cart)

    const emptyCart = useCallback(() => {
        setCart(new Map())
    }, [setCart])

    useEffect(() => {
        return () => emptyCart()
    }, [emptyCart])

    return (
        <>
            <div className="col-md-8 offset-md-2 p-0">
                <div className="image-and-confirmation-container">
                    <div className="img-container">
                        <img src="images/confetti.svg" alt="confetti icon" />
                    </div>
                    <h1 className="order-completed-heading">{"Order Completed!"}</h1>
                </div>
                <Accordion>
                    <Accordion.Item>
                        <Accordion.Header>
                            <p className="accordion-header">{`Your ${purchasedCarats.length} Cosmic Carat(s)`}</p>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>{orderNumber}</p>
                            {purchasedCarats.map((entry) => {
                                const carat = carats.get(entry[0])
                                const qty = entry[1]
                                return (
                                    <div className='sub-list-container'>
                                        <div>{carat.name}</div>
                                        <div>{`$ ${carat.price} (x${qty})`}</div>
                                    </div>
                                )
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <div className="list-container">
                    <div className="sub-list-container">
                        <p className="total-amount">Total</p>
                        <p className="total-amount">{`$ ${price}`}</p>
                    </div>
                </div>

                <div className="message-container">
                    <p className="message-alignment">{"A donation has been made to some charity or cause at no extra charge to you"}</p>
                </div>

                <div className="buttons-container">
                    <div className="continue__btn__container">
                        <Link to="/shop">
                            <button className="primary-button-style">Continue Shopping</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderCompleted
