import React from 'react';
import SwipeableCards from '../../sub-components/SwipeableCards';

const BestSelling = ({ range, carats, onCaratSelected }) => {
    const ids = carats.map(carat => carat.id).join()
    return (
        <SwipeableCards
            primaryCaptionFont={true}
            title={'Best Selling'}
            subTitle={range}
            link={{
                to: `/seeall?title=Best Selling&numOfCol=3&ids=${ids}&type=carat`,
                text: 'See all'
            }}
            slidesPerView={3}
            pagination={false}
            actions={carats.map(carat => {
                return { action: () => onCaratSelected(carat) }
            })}
            images={carats.map(carat => {
                return {
                    src: carat.imageUrl,
                    alt: carat.name
                }
            })}
            textOverlays={carats.map(carat => `$${carat.price}`)}
            captions={carats.map(carat => carat.name)}
        />
    );
};

export default BestSelling;