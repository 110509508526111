import React from 'react'
import { Link } from 'react-router-dom'

const CartLink = ({ selected }) => {
    return (
        <Link to="/cart">
            <svg width="28" height="26" viewBox="0 0 28 26" fill={ selected ? "#565656" : "#A3A3A3" } xmlns="http://www.w3.org/2000/svg">
                <path d="M27.7501 6.20324C27.6213 6.01715 27.4493 5.86504 27.2489 5.75996C27.0485 5.65487 26.8256 5.59993 26.5993 5.59986H7.466L5.85044 1.72196C5.63862 1.21128 5.27992 0.775009 4.81983 0.468449C4.35974 0.161889 3.81898 -0.00114943 3.26611 6.09997e-06H0V2.79993H3.26611L9.90754 18.7385C10.0139 18.9935 10.1934 19.2113 10.4233 19.3646C10.6533 19.5178 10.9234 19.5995 11.1997 19.5995H22.3994C22.9832 19.5995 23.5054 19.2369 23.7112 18.6923L27.9111 7.49261C27.9904 7.28068 28.0173 7.05267 27.9892 6.82811C27.9612 6.60356 27.8791 6.38914 27.7501 6.20324ZM21.4292 16.7996H12.1335L8.63357 8.39978H24.5791L21.4292 16.7996Z" />
                <path d="M11.8997 25.1994C13.0595 25.1994 13.9996 24.2592 13.9996 23.0995C13.9996 21.9397 13.0595 20.9995 11.8997 20.9995C10.7399 20.9995 9.79974 21.9397 9.79974 23.0995C9.79974 24.2592 10.7399 25.1994 11.8997 25.1994Z" />
                <path d="M21.6994 25.1994C22.8592 25.1994 23.7994 24.2592 23.7994 23.0995C23.7994 21.9397 22.8592 20.9995 21.6994 20.9995C20.5397 20.9995 19.5995 21.9397 19.5995 23.0995C19.5995 24.2592 20.5397 25.1994 21.6994 25.1994Z" />
            </svg>
        </Link>
    )
}

export default CartLink
