import './shop.css'

import TodaysTrending from './TodaysTrending'
import BestSelling from './BestSelling'
import TopCategories from './TopCategories'
import { getTrending, getBestSelling, getTopCategories } from '../../../BackendAPI'
import { mergeMaps } from '../../../Util'
import { useQuery } from 'react-query'

const Shop = ({
    history,
    onCaratSelected,
    setCarats,
    setCart,
    setModalOptions,
    setShowModal
}) => {

    const trendingQuery = useQuery({
        queryKey: ['trending'],
        queryFn: getTrending,
        initialData: {},
    })

    const bestSellingQuery = useQuery({
        queryKey: ['best_selling'],
        queryFn: getBestSelling,
        initialData: {},
    })

    const topCategoriesQuery = useQuery({
        queryKey: ['top_categores'],
        queryFn: getTopCategories,
        initialData: [],
    })

    const quickAddToCart = (carat) => {
        setCarats(
            previousCarats => mergeMaps(previousCarats, new Map([[carat.id, carat]]))
        )

        setCart(
            previousCart => mergeMaps(previousCart, new Map([[carat.id, 1]]), (qty1, qty2) => qty1 + qty2)
        )

        setModalOptions({
            icon: carat.imageUrl,
            message: `${carat.name} added to cart!`,
            onClickPrompt: 'Continue',
            onClick: () => setShowModal(false),
            onClose: () => setShowModal(false)
        })
        setShowModal(true)
    }

    const { data: trending } = trendingQuery;
    const { data: bestSelling } = bestSellingQuery;
    const { data: topCategories } = topCategoriesQuery;

    return (
        <div className="shop-container">
            {
                trending ?
                    <TodaysTrending
                        date={trending.date ? trending.date : ""}
                        carats={trending.carats ? trending.carats : []}
                        onCaratSelected={(carat) => onCaratSelected(history, carat)}
                        quickAddToCart={quickAddToCart}
                    /> :
                    <div>{"Loading..."}</div>
            }
            {
                bestSelling ?
                    <BestSelling
                        range={bestSelling.range ? bestSelling.range : ""}
                        carats={bestSelling.carats ? bestSelling.carats : []}
                        onCaratSelected={(carat) => onCaratSelected(history, carat)}
                    /> :
                    <div>{"Loading..."}</div>
            }
            {
                topCategories ?
                    <TopCategories history={history} categories={topCategories} /> :
                    <div>{"Loading..."}</div>
            }
        </div>
    )
}
export default Shop
