import React from 'react';
import ActionStrip from './ActionStrip'

import './DetailOptionals.css'

const DetailOptionals = ({
    subHeading,
    subSubHeading,
    smallHeading,
    touchables,
}) => {
    return (
        <div>
            {smallHeading && <p>{smallHeading}</p>}
            {subHeading && <h3 className="sub-heading">{subHeading}</h3>}
            {subSubHeading && <h4 className="sub-sub-heading">{subSubHeading}</h4>}
            {touchables && touchables && <ActionStrip touchables={touchables} />}
        </div>
    )
}

export default DetailOptionals
