import React, { useState, useRef, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './vault.css'
import 'swiper/swiper.min.css'
import "swiper/components/pagination/pagination.min.css"
import 'swiper/swiper.scss'

import { loadFile, uploadFile } from '../../BackendAPI'
import { calculateSize } from '../../Util'

const ACCEPTABLE_VIDEO_EXTENSIONS = ['mov', 'mp4'];
const ACCEPTABLE_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'bmp']
const MAX_WIDTH = 100
const MAX_HEIGHT = 100

const titles = {
    coupons: 'Add Free Cosmic Coupons',
    pictureAndVideo: 'Add pictures or videos',
    gifsAndStickers: 'Add GIF\'s or stickers'
}

const Vault = ({
    gift,
    setGift,
    gifts,
    setGifts,
    setVaultOpen,
    couponCart
}) => {

    const [show, setShow] = useState(true)
    const [preview, setPreview] = useState([])
    const [gifPreview, setGifPreview] = useState([])

    const [fileExtensions, setFileExtensions] = useState([])

    useEffect(() => {
        // TODO: get media from BackEndAPI (testing with local storage)
    }, [])

    useEffect(() => {

    }, [gifPreview])

    const handleClose = () => {
        setShow(false)
        setVaultOpen(false)
    }

    const onChangeNFT = (isNFT) => {
        const newGift = {
            vault: {
                coupons: gift.vault.coupons,
                media: gift.vault.media,
                isNFT
            },
            carat: gift.carat
        }
        setGift(newGift)
        setGifts(gifts.map(oldGift => oldGift.carat.id === gift.carat.id ? newGift : oldGift))
    }

    const imageUploader1 = useRef(null)

    const onFilesSelected = (fileSelecteEvent) => {

        let reader

        const shrinkAndCacheFile = (image, onloadEvent, reader, fileName) => {
            URL.revokeObjectURL(image.src)

            const [newWidth, newHeight] = calculateSize(image, MAX_WIDTH, MAX_HEIGHT)

            const canvas = document.createElement("canvas")
            canvas.width = newWidth
            canvas.height = newHeight

            const context = canvas.getContext("2d")
            context.drawImage(image, 0, 0, newWidth, newHeight)

            canvas.toBlob(
                (blob) => {

                    reader = new FileReader()
                    reader.onload = (fileLoadedEvent) => {

                        preview.push(fileLoadedEvent.target.result)
                        setPreview([...new Set(preview)])

                        uploadFile(fileLoadedEvent.target.result, fileName).then(mediaItem => {

                            gift.vault.media.push(mediaItem)

                            if (mediaItem) {
                                const newGift = {
                                    vault: {
                                        coupons: gift.vault.coupons,
                                        media: gift.vault.media,
                                        isNFT: gift.vault.isNFT
                                    },
                                    carat: gift.carat
                                }
                                setGift(newGift)
                                setGifts(gifts.map(oldGift => oldGift.carat.id === gift.carat.id ? newGift : oldGift))
                            }
                        })
                    }
                    reader.readAsDataURL(blob)

                },
                "image/jpeg",
                1.0
            )
        }

        for (let i = 0; i < fileSelecteEvent.target.files.length; i++) {
            const file = fileSelecteEvent.target.files[i]
            const blobURL = URL.createObjectURL(file)
            const fileName = file.name

            setFileExtensions([
                ...fileExtensions,
                file.name.split('.').pop()
            ])

            const image = new Image()
            image.src = blobURL

            image.onerror = (errorEvent) => {
                alert(`Cannot load image: errorEvent = ${JSON.stringify(errorEvent)}`)
                URL.revokeObjectURL(image.src)
            }

            image.onload = (onloadEvent) => {
                shrinkAndCacheFile(image, onloadEvent, reader, fileName)
            }
        }
    }

    const gifFileObject = []

    const onGifsSelected = gifSelecteEvent => {
        gifFileObject.push(gifSelecteEvent.target.files);

        let reader;
        for (var i = 0; i < gifSelecteEvent.target.files.length; i++) {

            reader = new FileReader();

            reader.onload = (gifLoadedEvent) => {

                gifPreview.push(gifLoadedEvent.target.result); // update the array instead of replacing the entire value of preview
                setGifPreview([...new Set(gifPreview)]); // spread into a new array to trigger rerender
            }

            reader.readAsDataURL(gifFileObject[0][i]);
        }

    }

    return (
        <div style={{ position: 'relative' }}>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={{ borderBottom: 'none' }} closeButton>
                    <h3 className='vault-modal-header-style'>Vault</h3>
                </Modal.Header>
                <Modal.Body>
                    <p className='title-styling'>{titles.coupons}</p>
                    <div className='upload-container'>
                        <Link className='margin-right' to='/couponselector'>
                            <img src='../../icons/boxed-plus-icon.svg' alt="Add free cosmic coupons" />
                        </Link>
                        <div className="horizontal-scroll">
                            {Array.from(gift.vault.coupons).map((coupon, index) => {
                                return (
                                    <div className="brand__container horizontal-scroll-spacing" style={{ display: 'inline-block' }}>
                                        <img className="brand__logo" src={coupon.logo} alt={coupon.brand_name} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <p className='title-styling'>{titles.pictureAndVideo}</p>
                    <div className="upload-container">
                        <>
                            <input
                                type="file"
                                accept="image/jpg, image/png, image/jpeg, video/*"
                                multiple
                                onChange={(event) => onFilesSelected(event)}
                                ref={imageUploader1}
                                style={{
                                    display: 'none'
                                }} />
                            <div className='margin-right' onClick={() => imageUploader1.current.click()}>
                                <img src='../../icons/boxed-plus-icon.svg' alt="Add free cosmic coupons" />
                            </div>
                        </>
                        <>
                            <div className="horizontal-scroll">
                                {gift.vault.media.length > 0 ?
                                    <>
                                        {
                                            gift.vault.media.map((mediaItem, index) => {
                                                if (ACCEPTABLE_VIDEO_EXTENSIONS.includes(mediaItem.fileExtension)) {
                                                    return (

                                                        <div className="brand__container horizontal-scroll-spacing">
                                                            <video loop autoPlay controls key={index} width="120px" height="120px" >
                                                                <source className="brand__logo" src={loadFile(mediaItem.cacheIndex) ? loadFile(mediaItem.cacheIndex) : mediaItem.fileUrl} type="video/mp4" width="20px" height="20px" />
                                                            </video>
                                                        </div>

                                                    )
                                                } else if (ACCEPTABLE_IMAGE_EXTENSIONS.includes(mediaItem.fileExtension)) {
                                                    return (

                                                        <div className="brand__container horizontal-scroll-spacing">
                                                            <img className="brand__logo" src={loadFile(mediaItem.cacheIndex) ? loadFile(mediaItem.cacheIndex) : mediaItem.fileUrl} alt={mediaItem.altText} key={index} />
                                                        </div>

                                                    )
                                                }
                                                return null
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            preview.map((fileSelected, index) => {
                                                const fileExtension = fileExtensions[index];

                                                if (ACCEPTABLE_VIDEO_EXTENSIONS.includes(fileExtension)) {
                                                    return (

                                                        <div className="brand__container horizontal-scroll-spacing">
                                                            <video loop autoPlay controls key={index} width="120px" height="120px" >
                                                                <source className="brand__logo" src={fileSelected} type="video/mp4" width="20px" height="20px" />
                                                            </video>
                                                        </div>

                                                    );
                                                }
                                                else if (ACCEPTABLE_IMAGE_EXTENSIONS.includes(fileExtension)) {
                                                    return (

                                                        <div className="brand__container horizontal-scroll-spacing">
                                                            <img className="brand__logo" src={fileSelected} alt="Uploaded Content" key={index} />
                                                        </div>

                                                    );

                                                }
                                                return null;
                                            })
                                        }
                                    </>

                                }

                            </div>
                        </>
                    </div >

                    <p className='title-styling'>{titles.gifsAndStickers}</p>
                    <div className="upload-container">
                        <>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={onGifsSelected}
                                style={{
                                    display: 'none'
                                }} />
                            <div className="margin-right" onClick={() => alert('Open GIFs and Stickers library from back end!')}>
                                <img src='../../icons/boxed-plus-icon.svg' alt="Add free cosmic coupons" />
                            </div>
                        </>
                        <div className="horizontal-scroll">
                            <>
                                {
                                    //TODO: Select "art" from gift
                                }
                            </>
                        </div>
                    </div>
                    <div className="nft-checkbox">
                        <input
                            id="isNFT"
                            type="checkbox"
                            checked={gift.vault.isNFT}
                            onChange={(event) => onChangeNFT(event.target.checked)}
                        />
                        <label for="isNFT">Preserve as NFTs</label>
                    </div>
                </Modal.Body >
                <Modal.Footer>
                    <button className='primary-button-style w-100' onClick={handleClose}>Seal Vault</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default Vault
