import React from 'react'
import { Link } from 'react-router-dom'

const PurchasedLink = ({ selected }) => {
    return (
        <Link to="/purchased">
            <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.4345 13.2277V6.43453C18.4345 4.9932 17.8619 3.61091 16.8428 2.59174C15.8236 1.57256 14.4413 1 13 1C11.5586 1 10.1763 1.57256 9.15717 2.59174C8.13799 3.61091 7.56543 4.9932 7.56543 6.43453V13.2277" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={selected ? "#565656" : "#A3A3A3"} />
                <path d="M2.20023 10.4018C2.22756 10.0613 2.38212 9.74364 2.63314 9.51201C2.88416 9.28037 3.21322 9.15179 3.55479 9.15186H22.4452C22.7868 9.15179 23.1158 9.28037 23.3669 9.51201C23.6179 9.74364 23.7724 10.0613 23.7998 10.4018L24.9913 25.2381C25.0213 25.612 24.9736 25.988 24.8511 26.3425C24.7287 26.6971 24.5341 27.0224 24.2798 27.2981C24.0254 27.5738 23.7167 27.7938 23.3732 27.9443C23.0296 28.0948 22.6586 28.1726 22.2835 28.1727H3.71646C3.34138 28.1726 2.97037 28.0948 2.62681 27.9443C2.28325 27.7938 1.97458 27.5738 1.72022 27.2981C1.46586 27.0224 1.27133 26.6971 1.14888 26.3425C1.02643 25.988 0.978702 25.612 1.00871 25.2381L2.19887 10.4018H2.20023Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" stroke={selected ? "#565656" : "#A3A3A3"} />
            </svg>
        </Link>
    )
}

export default PurchasedLink
