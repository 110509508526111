import React, { useCallback, useState } from 'react'
import { search } from '../../BackendAPI'
import Autocomplete from 'react-autocomplete'

import './SearchBar.scss'

const MILLISECOND_SEARCH_DELAY = 500;

const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> {parts.map((part, i) =>
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
            {part}
        </span>)
    } </span>;
}

const SearchBar = ({ history }) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [searchResults, setSearchResults] = useState([])

    const debounce = (callback, delayMilliseconds) => {
        let timer;
        return (...args) => {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                callback.apply(context, args);
            }, delayMilliseconds);
        };
    };

    const onChange = (value) => {
        search(value).then(results => setSearchResults(results))
    }

    // eslint-disable-next-line
    const debouncedOnChange = useCallback(debounce(onChange, MILLISECOND_SEARCH_DELAY), []);

    return (
        <Autocomplete
            wrapperStyle={{}}
            menuStyle={{}}
            inputProps={{
                onBlur:() => {
                    setSearchQuery('')
                    setSearchResults([])
                }
            }}
            renderInput={props => (
                <div className="searchbar">
                    <input id="search" placeholder="Search" {...props} />
                    {searchQuery.length > 0 ?
                        <img
                            src="images/clear-search-icon.svg"
                            className="rightAlignedIcon"
                            alt="Clear search"
                            onClick={() => setSearchQuery('')}
                        /> :
                        <img src="images/search-icon.svg" alt="Search" className="rightAlignedIcon" />
                    }
                </div>
            )}
            getItemValue={item => item.text}
            items={searchResults}
            renderItem={(item, isHighlighted) => (
                <div
                    key={item.doc_id}
                    style={{ background: isHighlighted ? 'lightblue' : 'white' }}
                    className="result"
                >
                    {getHighlightedText(item.text, searchQuery)}
                </div>
            )}
            value={searchQuery}
            onChange={event => {
                setSearchQuery(event.target.value)
                debouncedOnChange(event.target.value)
            }}
            onSelect={(value, item) => {
                setSearchQuery(value)
                const url = item.collection === "cosmic_carats"
                    ? `./caratdetails?id=${item.doc_id}`
                    : `./seeall?title=${value}&numOfCol=4&ids=${item.doc_id}&type=category`;
                history.pushState({}, "", url);
                history.go();
            }}
        />
    )
}

export default SearchBar