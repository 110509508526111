import React from 'react'
import { Link } from 'react-router-dom'

const HomeLink = ({ selected }) => {
    return (
        <Link to="/shop">
            <svg width="28" height="26" viewBox="0 0 28 26" fill={selected ? "#565656" : "#A3A3A3"} xmlns="http://www.w3.org/2000/svg">
                <path d="M12.2676 11.5696L12 11.8336L11.7312 11.5696C11.2317 11.0764 10.5581 10.7999 9.85615 10.7999C9.15424 10.7999 8.4806 11.0764 7.98111 11.5696C7.73321 11.808 7.536 12.0941 7.40129 12.4106C7.26658 12.7271 7.19714 13.0676 7.19714 13.4116C7.19714 13.7556 7.26658 14.096 7.40129 14.4126C7.536 14.7291 7.73321 15.0151 7.98111 15.2536L12 19.2005L16.0189 15.2536C16.2668 15.0151 16.464 14.7291 16.5987 14.4126C16.7334 14.096 16.8028 13.7556 16.8028 13.4116C16.8028 13.0676 16.7334 12.7271 16.5987 12.4106C16.464 12.0941 16.2668 11.808 16.0189 11.5696C15.5192 11.0763 14.8453 10.7998 14.1432 10.7998C13.4411 10.7998 12.7673 11.0763 12.2676 11.5696Z" />
                <path d="M23.6487 11.1521L12.8484 0.351849C12.7371 0.240312 12.6048 0.151824 12.4592 0.0914501C12.3137 0.031076 12.1576 0 12 0C11.8424 0 11.6863 0.031076 11.5408 0.0914501C11.3952 0.151824 11.2629 0.240312 11.1516 0.351849L0.351352 11.1521C0.183577 11.3199 0.0693267 11.5337 0.0230447 11.7665C-0.0232374 11.9992 0.000526752 12.2404 0.0913327 12.4597C0.182139 12.6789 0.335909 12.8663 0.533205 12.9982C0.730502 13.1301 0.962465 13.2005 1.19977 13.2005H2.3998V21.6007C2.3998 22.9243 3.47622 24.0008 4.79985 24.0008H19.2002C20.5238 24.0008 21.6002 22.9243 21.6002 21.6007V13.2005H22.8002C23.0375 13.2005 23.2695 13.1301 23.4668 12.9982C23.6641 12.8663 23.8179 12.6789 23.9087 12.4597C23.9995 12.2404 24.0232 11.9992 23.977 11.7665C23.9307 11.5337 23.8164 11.3199 23.6487 11.1521ZM19.2014 21.6007H4.79985V10.0985L12 2.8983L19.2002 10.0985V15.6006L19.2014 21.6007Z" />
            </svg>
        </Link>
    )
}

export default HomeLink
