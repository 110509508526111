import React from 'react';
import './EditableLabel.scss'

const EditableLabel = ({ imgSrc, imgAlt, labelDescription, label, children }) => {

    return (
        <div className="editable-label-container">
            <p className="label-description">{labelDescription}</p>
            <div className="label-container">
                <div className="label-image">
                    <img src={imgSrc} alt={imgAlt} />
                </div>
                <div className="label">
                    {
                        label ?
                            <p className="label-text">{label}</p> :
                            <p className="no-label-text">{`Click pencil to edit ${labelDescription}`}</p>
                    }
                </div>
                <div className="label-image">
                    {children}
                </div>
            </div>
        </div>
    )
};

export default EditableLabel;
