import React, { useRef, useState, useCallback } from 'react'
import EditableLabel from '../sub-components/EditableLabel'
import ImageEditor from '../sub-components/ImageEditor'
import { getCroppedImg } from '../../Util'

import './profile.css'

function Profile(props) {

    const uploadedImage = useRef(null)
    const imageUploader = useRef(null)

    const [file, setFile] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const [modalImageSrc, setModalImageSrc] = useState(null)

    const setCroppedImage = useCallback(async (modalImageSrc, croppedAreaPixels, rotation) => {
        try {
            const croppedImage = await getCroppedImg(
                modalImageSrc,
                croppedAreaPixels,
                rotation
            )
            
            const { current } = uploadedImage
            current.src = croppedImage

        } catch (e) {
            // TODO: log error somewhere
            console.error(e)
        }
    }, [])

    const handleImageUpload = (attribute, files) => {
        const [file] = files;
        if (file) {
            setFile(file)
            const reader = new FileReader();
            const { current } = uploadedImage;
            current.file = file;
            reader.onload = (event) => {
                setModalImageSrc(event.target.result)
                setShowModal(true)
            }
            reader.readAsDataURL(file);
        }
    };

    const addUserProfileDetails = (attribute, value) => {
        props.setUserProfile({ ...props.userProfile, [attribute]: value })
    }

    const openEditableModal = (editModalOptions) => {
        props.setModalOptions(editModalOptions)
        props.setShowModal(true)
    }

    return (
        <>
            <div className="profile-image">
                <input
                    type="file"
                    accept="image/*"
                    onChange={(event) => handleImageUpload("imgUrl", event.target.files)}
                    ref={imageUploader}
                    style={{ display: 'none' }} />
                <div className="centered-div" onClick={() => imageUploader.current.click()}>
                    <img
                        ref={uploadedImage}
                        className="upload__img__format"
                        alt="upload profile"
                        src={props.userProfile.imgUrl ? props.userProfile.imgUrl : './icons/profile-image-placeholder.svg'}
                    />
                    <div className="img-upload-camera-icon">
                        <img src='./icons/camera-icon.svg' alt="upload profile" />
                    </div>
                </div>
            </div>

            <EditableLabel
                imgSrc={"./icons/person-icon.svg"}
                imgAlt={"profile icon"}
                labelDescription={"Name"}
                label={props.userProfile.name}
            >
                <img
                    src="./icons/pencil-icon.svg"
                    alt="edit profile name"
                    onClick={() => openEditableModal({
                        type: "text",
                        icon: "./icons/person-icon.svg",
                        editable: {
                            placeholder: "Enter name here",
                            onChange: (event) => addUserProfileDetails("name", event.target.value),
                            onSubmit: (newName) => {
                                props.setShowModal(false)
                                alert(`TODO: submit ${newName} to backend`)
                            }
                        },
                        onClickPrompt: "Submit",
                        onClose: () => { }
                    })}
                />
            </EditableLabel>

            <EditableLabel
                imgSrc={"./icons/info-icon.svg"}
                imgAlt={"bio icon"}
                labelDescription={"Bio"}
                label={props.userProfile.biography}
            >
                <img
                    src="./icons/pencil-icon.svg"
                    alt="edit profile bio"
                    onClick={() => openEditableModal({
                        type: "text",
                        icon: "./icons/info-icon.svg",
                        editable: {
                            placeholder: "Enter bio here",
                            onChange: (event) => addUserProfileDetails("biography", event.target.value),
                            onSubmit: (newBio) => {
                                props.setShowModal(false)
                                alert(`TODO: submit ${newBio} to backend`)
                            }
                        },
                        onClickPrompt: "Submit",
                        onClose: () => { }
                    })}
                />
            </EditableLabel>

            <EditableLabel
                imgSrc={"./icons/phone-icon.svg"}
                imgAlt={"phone icon"}
                labelDescription={"Telephone"}
                label={props.userProfile.telephone}
            >
                <img
                    src="./icons/pencil-icon.svg"
                    alt="edit profile phone number"
                    onClick={() => openEditableModal({
                        type: "tel",
                        icon: "./icons/phone-icon.svg",
                        editable: {
                            placeholder: "Enter phone number here",
                            onChange: (event) => addUserProfileDetails("telephone", event.target.value),
                            onSubmit: (newPhoneNumber) => {
                                props.setShowModal(false)
                                alert(`TODO: submit ${newPhoneNumber} to backend`)
                                // TODO: submit newPhoneNumber to backend
                            }
                        },
                        onClickPrompt: "Submit",
                        onClose: () => { }
                    })}
                />
            </EditableLabel>

            <ImageEditor
                showModal={showModal}
                imageSrc={modalImageSrc}
                onCropCancel={() => setShowModal(false)}
                onCropConfirm={(croppedAreaPixels, rotation) => {                    
                    setCroppedImage(modalImageSrc, croppedAreaPixels, rotation)

                    alert(`TODO: Send raw file (${file.name} data (event.target.result) and it's meta-data to back end, get imgUrl)`)
                    const imgUrl = undefined // should come from backend
                    props.setUserProfile({ ...props.userProfile, "imgUrl": imgUrl })
                    setShowModal(false)
                }}
            />
        </>
    )
}

export default Profile
