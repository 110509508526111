import trendingCarats from './dummy_data/carats/trending.json'
import bestSellersCarats from './dummy_data/carats/bestSellersCarats.json'
import categories from './dummy_data/categories/categoriesList.json'
import creditCardsFromDatabase from './dummy_data/payment-options/creditCards.json'

import hash from 'object-hash'

const ROGERS = 70
const BELL = 20
const STARLINK = 7

const DELAY = [
    ROGERS,
    BELL,
    STARLINK
]

// browser cache
const MAX_CACHE_SIZE = 250 // max number of files of size up to 10KB in cache (using localStorage <= 250 * 20KB = 5MB (max localStorage size quota))

const waitRandomly = async () => {
    await new Promise(resolution => setTimeout(resolution, DELAY[Math.floor(Math.random() * 3 + 1)]))
}

const host = process.env.NODE_ENV !== 'production' ?
    "http://localhost:5001/cosmic-carat-api/us-central1/api" :
    "https://us-central1-cosmic-carat-api.cloudfunctions.net/api";

// TODO: get from real back end (Stripe API)
export const getCreditCards = async (userId, passsword) => {
    await waitRandomly()
    return creditCardsFromDatabase
}

// TODO: connect to Stripe API
export const addNewCreditCardToBackend = async (creditCards) => {
    await waitRandomly()
    const success = Math.floor(Math.random() * 10) % 2 === 0
    return {
        success,
        errorMessage: success ? "" : "Network error"
    }
}

// TODO: connect to Stripe API
export const payNow = async (cart) => {
    await waitRandomly()
    // TODO: use cart to calculate total price (see App.getTotalPrice)
    const success = Math.floor(Math.random() * 10) % 2 === 0
    return {
        success,
        errorMessage: success ? "" : Math.random() * 10 < 5 ? "Network error" : "Payment declined"
    }
}

export const search = async (searchTerm) => {
    const url = `${host}/search?q=${searchTerm}`;
    const res = await fetch(url, { mode: 'cors' });
    const searchResults = await res.json();
    return searchResults;
}

export const getItems = async (ids, type) => {
    const url = `${host}${type === 'carat' ? '/carats' : '/categories'}?ids=${ids}`;
    const res = await fetch(url, { mode: 'cors' });
    const items = await res.json();

    return items.filter(item => item !== undefined).map(item => {
        return {
            id: item.id,
            frameUrl: item.frame,
            name: item.name,
            price: item.price,
            imageUrl: item.image_path,
            imageAlt: item.name,
            caption: item.name,
            onClick: (history) => {
                const url = type === "carat" ?
                    `./caratdetails?id=${item.id}` :
                    `./seeall?title=${item.name}&numOfCol=4&ids=${item.cosmic_carat_ids.join()}&type=carat`;
                history.push(url)
            }
        }
    });
}

export const getTrending = async () => {
    const MIN_TODAYS_TRENDING_TO_SHOW = 5;

    const url = `${host}/todays_trending`;
    const res = await fetch(url, { mode: 'cors' });
    const items = await res.json();

    if (items.length < MIN_TODAYS_TRENDING_TO_SHOW) {
        const url = `${host}/carats`;
        const res = await fetch(url, { mode: 'cors' });
        const allItems = await res.json();
        items.push(...allItems);
    }

    const carats = items.map(item => {
        return {
            id: item.id,
            frameUrl: item.frame,
            name: item.name,
            price: item.price,
            imageUrl: item.image_path,
            imageAlt: item.name,
            caption: item.name
        }
    })

    const date = (new Date()).toLocaleDateString(
        window.navigator.language,
        {
            weekday: 'short',
            month: 'long',
            day: 'numeric'
        }
    )

    return { date, carats }
}

export const getBestSelling = async () => {
    const MIN_BEST_SELLING_TO_SHOW = 3;

    const url = `${host}/best_selling`;
    const res = await fetch(url, { mode: 'cors' });
    const items = await res.json();

    if (items.length < MIN_BEST_SELLING_TO_SHOW) {
        const url = `${host}/carats`;
        const res = await fetch(url, { mode: 'cors' });
        const allItems = await res.json();
        items.push(...allItems);
    }

    const carats = items.map(item => {
        return {
            id: item.id,
            frameUrl: item.frame,
            name: item.name,
            price: item.price,
            imageUrl: item.image_path,
            imageAlt: item.name,
            caption: item.name
        }
    })

    return { range: 'This week', carats }
}

export const getTopCategories = async () => {
    const url = `${host}/categories`;
    const res = await fetch(url, { mode: 'cors' });
    const items = await res.json();

    return items.map(item => {
        return {
            id: item.id,
            frameUrl: item.frame,
            name: item.name,
            price: item.price,
            imageUrl: item.image_path,
            imageAlt: item.name,
            caption: item.name,
            caratIds: item.cosmic_carat_ids ? item.cosmic_carat_ids.join(',') : ''
        }
    })
}

// TODO: get from real back end
export const getRelatedItems = async (id, type) => {
    await waitRandomly()

    // don't forget to use 'id' in parameter above to get related carats when actually implementing the back end

    if (type === 'carat') {
        return [
            ...trendingCarats.map(item => {
                return {
                    id: item.id,
                    frameUrl: item.frame,
                    name: item.name,
                    price: item.price,
                    imageUrl: item.image_path,
                    imageAlt: item.name,
                    caption: item.name
                }
            }),
            ...bestSellersCarats.map(item => {
                return {
                    id: item.id,
                    frameUrl: item.frame,
                    name: item.name,
                    price: item.price,
                    imageUrl: item.image_path,
                    imageAlt: item.name,
                    caption: item.name
                }
            })
        ]
    }

    return categories.map(item => {
        return {
            id: item.id,
            frameUrl: item.frame,
            name: item.name,
            price: item.price,
            imageUrl: item.image_path,
            imageAlt: item.name,
            caption: item.name
        }
    })
}

// TODO: get from real back end
export const requestGiftUrl = async (gift) => {
    await waitRandomly()
    // encrypt gift with 2-way encryption key before hashing
    const giftId = hash(gift)
    return `${window.location.origin}/giftid=${giftId}`
}

export const uploadFile = async (fileData, fileName) => {
    await waitRandomly()

    // TODO: Acutally upload full size fileData to backend and return resultant file URL
    const fileUrl = null

    const cache = localStorage.getItem('cache') ?
        new Map(JSON.parse(localStorage.getItem('cache'))) :
        new Map()

    const cacheIndex = cache.size % MAX_CACHE_SIZE
    cache.set(cacheIndex, fileData)

    localStorage.setItem('cache', JSON.stringify(cache))

    return {
        cacheIndex,
        fileUrl,
        altText: fileName.split('.').shift(),
        fileExtension: fileName.split('.').pop()
    }
}

export const loadFile = (cacheIndex) => {

    const cache = localStorage.getItem('cache') ?
        new Map(JSON.parse(localStorage.getItem('cache'))) :
        new Map()

    return cache.get(cacheIndex)

}
