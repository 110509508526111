import React from 'react';
import './FormInput.scss'

const Forms = ({
    type,
    name,
    username,
    recipientName,
    email,
    number,
    password,
    placeholder,
    onChange,
    message,
    label,
    // onFocus This is needed for accessability 
}) => {
    return (
        <div>
            <form className="label-and-input-container">
                <div>
                    <label className="label-style" htmlFor={name} >{label}</label>
                </div>
                <div>
                    <input
                        className="input-style"
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        onChange={onChange}
                        username={username}
                        password={password}
                        recipientName={recipientName}
                        email={email}
                        message={message}
                    />
                </div>
            </form>
        </div>
    )
};

export default Forms;
