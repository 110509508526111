import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import {Link} from 'react-router-dom'


function SentConfirmationModal(props) {

    const [show, setShow] = useState(true)

    const handleClose = () => {
        setShow(false)
        props.setShowSendConfirm(false)
    }


    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                    <div>
                        <img src='/images/shareConfirmation.png' alt="share confirmation" />
                    </div>
                </Modal.Body>
                <Modal.Body>
                    <p>Congratulations your gift has been sent to {props.receiverDetails.name} </p>
                </Modal.Body>
                <Modal.Body>
                    <div className="row mr-0">
                        <div className="col col-sm-8 col-md-12">
                            <div className="d-flex justify-content-center">
                                <div className="mt-5">
                                    <Link to ='/shop'>
                                    <button>Back to Home</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SentConfirmationModal
